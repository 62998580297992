export const CUSTOMERS_PAGE_SIZE = 20;
export const CUSTOMERS_LIMIT_999 = 999;
export const CUSTOMERS_OFFSET = 0;

export const FARMS_PAGE_SIZE = 10;
export const FARMS_OFFSET = 0;
export const FARMS_LIMIT_999 = 999;

export const FIELDS_PAGE_SIZE = 40;
export const FIELDS_OFFSET = 0;
export const FIELDS_BY_CUSTOMER_AND_FILE_SIZE = 999;

export const APPLICATIONS_PAGE_SIZE = 40;

export const SERVICE_ORDERS_PAGE_SIZE = 20;
export const SERVICE_ORDERS_OFFSET = 0;

export const FILES_PAGE_SIZE = 40;
export const FILES_OFFSET = 0;

export const RESTRICTED_LOCATIONS_PAGE_SIZE = 40;
export const RESTRICTED_LOCATIONS_OFFSET = 0;

export const AERIAL_COMPANY_LIMIT = 10;
export const AERIAL_COMPANY_OFFSET = 0;
export const AERIAL_COMPANY_999 = 999;

export const OPERATOR_OFFSET = 0;
export const OPERATOR_LIMIT = 999;

export const VEHICLE_OFFSET = 0;
export const VEHICLE_LIMIT = 999;

export const SEASON_LIMIT = 10;
export const SEASON_OFFSET = 0;
export const SEASON_LIMIT_999 = 999;
export const FARM_LEAF_LIMIT_999 = 2000;

export const INITIAL_CUSTOMER_USER_LIMIT = 10;
export const INITIAL_CUSTOMER_USER_PAGE = 0;
export const INITIAL_CUSTOMER_USER_SEARCH = "";

export const DRAWER_WIDTH = 315;
export const NAV_WIDTH = 104;
export const DEFAULT_HEIGHT = "calc(100vh - 3rem)";

export const FLOW_RATE_GROUP_COLOR = {
  1: "#001029",
  2: "#00317A",
  3: "#094DB3",
  4: "#1975FF",
  5: "#3385FF",
  6: "#FFB219",
  7: "#B37700",
  8: "#66470A",
  9: "#332405",
};

export const FEET_IN_METER_VALUE = 3.28084;

export const MIN_RESTRICTION_BUFFER = 0;
export const MAX_RESTRICTION_BUFFER = 3000;
export const MAX_RESTRICTION_BUFFER_IMPERIAL = 9000;

export const DEFAULT_INTERSECTION_TOLERANCE_TO_FIELD = 0;

export const NAME_VALUE_LIST = "nameValueList";

export const REGEX_INVALID_CHARS = /["/:*?<>|'`~"\\!"]/g;
export const INVALID_CHARS = "/ : * ? < > | ' ` ~ \" !";
