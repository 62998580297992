import { gql } from "@apollo/client";

export const LIST_SERVICE_ORDERS = gql`
  query ListServiceOrders(
    $findServiceOrderInput: FindCustomerServiceOrderInput!
    $paginateServiceOrderInput: PaginateInput
    $sortServiceOrderInput: SortCustomerServiceOrderInput
  ) {
    findCustomerServiceOrder(
      findServiceOrderInput: $findServiceOrderInput
      paginateServiceOrderInput: $paginateServiceOrderInput
      sortServiceOrderInput: $sortServiceOrderInput
    ) {
      serviceOrders {
        id
        slug
        status
        createdAt
        applications {
          id
          analysisMethod
          applicationType
          status
          schedules {
            id
            startedIn
          }
          season {
            id
            crop
          }
          logs {
            id
            filename
            status
          }
          images {
            id
            metadata {
              url
            }
          }
        }
      }
      paginate {
        limit
        offset
        total
      }
    }
  }
`;

export const LIST_SERVICE_ORDER_SCHEDULES = gql`
  query ListServiceOrderSchedules(
    $findServiceOrderInput: FindCustomerServiceOrderInput!
  ) {
    findCustomerServiceOrder(findServiceOrderInput: $findServiceOrderInput) {
      serviceOrders {
        id
        slug
        applications {
          id
          schedules {
            id
            startedIn
            finishedIn
            comments
            runwayDistance
            pilot {
              id
              licenseId
              name
              aerialCompany {
                id
                name
              }
            }
            vehicle {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const LIST_SERVICE_ORDER_ID_SLUG = gql`
  query ListServiceOrderIdSlug(
    $findServiceOrderInput: FindCustomerServiceOrderInput!
  ) {
    findCustomerServiceOrder(findServiceOrderInput: $findServiceOrderInput) {
      serviceOrders {
        id
        slug
      }
    }
  }
`;

export const GET_SERVICE_ORDER = gql`
  query GetServiceOrder($customerId: ID!, $serviceOrderId: ID!) {
    findCustomerServiceOrderById(
      customerId: $customerId
      serviceOrderId: $serviceOrderId
    ) {
      id
      slug
      observation
      applications {
        id
        status
        applicationType
        schedules {
          startedIn
          finishedIn
          status
          vehicle {
            id
            name
            aerialCompany {
              id
              name
            }
          }
          pilot {
            id
            licenseId
            name
          }
        }
        layers {
          id
          bbox
          type
          metadata {
            url
          }
        }
        products {
          id
          dosage
          deletedAt
          customerProduct {
            id
            alias
            classType
          }
        }
        season {
          id
          crop
          startedIn
          finishedIn
        }
        fields {
          id
          area
          boundaries {
            coordinates
            type
          }
          field {
            id
            name
            area
            farmHierarchy
            boundaries {
              coordinates
              type
            }
            farm {
              id
              name
            }
          }
        }
        logs {
          id
          format
          filename
          status
          metadata {
            endDateTime
            startDateTime
          }
        }
        plannedAverageRateFlow
        plannedAverageHeight
      }
    }
  }
`;

export const GET_SERVICE_ORDER_SCHEDULE = gql`
  query GetServiceOrderSchedules($customerId: ID!, $serviceOrderId: ID!) {
    findCustomerServiceOrderById(
      customerId: $customerId
      serviceOrderId: $serviceOrderId
    ) {
      id
      slug
      applications {
        id
        schedules {
          id
          startedIn
          finishedIn
          comments
          runwayDistance
          pilot {
            id
          }
          vehicle {
            id
          }
        }
      }
    }
  }
`;

export const GET_SERVICE_ORDER_FIELDS = gql`
  query GetServiceOrderFields($customerId: ID!, $serviceOrderId: ID!) {
    findCustomerServiceOrderById(
      customerId: $customerId
      serviceOrderId: $serviceOrderId
    ) {
      id
      slug
      applications {
        id
        fields {
          id
          area
          boundaries {
            type
            coordinates
          }
          field {
            id
            name
            area
            farmHierarchy
          }
        }
      }
    }
  }
`;

export const GET_SERVICE_ORDER_POST_APPLICATION = gql`
  query GetServiceOrderPostApplication($customerId: ID!, $serviceOrderId: ID!) {
    findCustomerServiceOrderById(
      customerId: $customerId
      serviceOrderId: $serviceOrderId
    ) {
      id
      slug
      applications {
        id
        applicationType
        status
        schedules {
          id
          startedIn
          finishedIn
          applicationLogs {
            id
            filename
          }
          pilot {
            id
            name
          }
          vehicle {
            id
            name
            aerialCompany {
              id
              name
            }
          }
        }
        fields {
          id
          area
          boundaries {
            coordinates
            type
          }
          field {
            id
            name
            farmHierarchy
            farm {
              id
            }
          }
        }
        season {
          id
          crop
        }
        logs {
          id
          filename
          status
          metadata {
            startDateTime
            endDateTime
          }
        }
        products {
          id
          dosage
        }
      }
    }
  }
`;
