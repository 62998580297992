import { gql } from "@apollo/client";

export const LIST_CUSTOMER_PRODUCTS = gql`
  query ListCustomerProducts(
    $findCustomerProductInput: FindCustomerProductInput!
    $paginateCustomerProductInput: PaginateInput
    $sortCustomerProductInput: SortCustomerProductInput
  ) {
    findCustomerProduct(
      findCustomerProductInput: $findCustomerProductInput
      paginateCustomerProductInput: $paginateCustomerProductInput
      sortCustomerProductInput: $sortCustomerProductInput
    ) {
      customerProducts {
        id
        alias
        manufacturerName
        measurementUnit
        classType
        amount {
          currency
          value
        }
      }
      total
    }
  }
`;

export const GET_CUSTOMER_PRODUCT = gql`
  query GetCustomerProduct($customerProductId: ID!) {
    findCustomerProductById(customerProductId: $customerProductId) {
      id
      alias
      manufacturerName
      measurementUnit
      classType
      amount {
        currency
        value
      }
    }
  }
`;
