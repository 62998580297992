import { gql } from "@apollo/client";

export const LIST_AERIAL_COMPANIES = gql`
  query ListAerialCompanies(
    $findAerialCompanyInput: FindAerialCompanyInput!
    $paginateAerialCompanyInput: PaginateInput
    $sortAerialCompanyInput: SortAerialCompanyInput
  ) {
    findCustomerAerialCompany(
      findAerialCompanyInput: $findAerialCompanyInput
      paginateAerialCompanyInput: $paginateAerialCompanyInput
      sortAerialCompanyInput: $sortAerialCompanyInput
    ) {
      aerialCompanies {
        id
        name
        documentNumber
        pilots {
          id
          licenseId
          name
        }
        aircrafts {
          id
          enrollmentId
          deletedAt
        }
        tractors {
          id
          name
          deletedAt
        }
      }
      total
    }
  }
`;

export const GET_AERIAL_COMPANY = gql`
  query GetAerialCompany($aerialCompanyId: ID!, $customerId: ID!) {
    findCustomerAerialCompanyById(
      aerialCompanyId: $aerialCompanyId
      customerId: $customerId
    ) {
      id
      name
      documentNumber
      pilots {
        id
        name
        licenseId
      }
      aircrafts {
        id
        enrollmentId
        model {
          id
          name
          manufacturer {
            id
            name
          }
        }
      }
      tractors {
        id
        name
        model {
          id
          name
          manufacturer {
            id
            name
          }
        }
      }
    }
  }
`;
