import { gql } from "@apollo/client";

export const CREATE_SERVICE_ORDER = gql`
  mutation CreateServiceOrder($input: CreateServiceOrderInput!) {
    createServiceOrder(input: $input) {
      id
      externalId
      applications {
        id
      }
    }
  }
`;

export const DELETE_SERVICE_ORDER = gql`
  mutation DeleteServiceOrder($deleteServiceOrderId: String!) {
    deleteServiceOrder(id: $deleteServiceOrderId)
  }
`;

export const UPDATE_SERVICE_ORDER = gql`
  mutation UpdateServiceOrder($input: UpdateServiceOrderInput!) {
    updateServiceOrder(input: $input) {
      id
    }
  }
`;

export const FINISH_CUSTOMER_UPLOAD_LOGS = gql`
  mutation FinishCustomerUploadLogs(
    $finishCustomerUploadLogs: FinishCustomerUploadLogsInput!
  ) {
    finishCustomerUploadLogs(
      finishCustomerUploadLogs: $finishCustomerUploadLogs
    ) {
      id
    }
  }
`;

export const CREATE_PRE_FLIGHT = gql`
  mutation CreatePreFlight($createPreFlightInput: CreatePreFlightInput!) {
    createPreFlight(createPreFlightInput: $createPreFlightInput) {
      id
      slug
      applications {
        id
      }
      pilotLapMapReport {
        url
        expiresIn
      }
    }
  }
`;

export const CREATE_POST_FLIGHT = gql`
  mutation CreatePostFlight($createPostFlightInput: CreatePostFlightInput!) {
    createPostFlight(createPostFlightInput: $createPostFlightInput) {
      id
      applications {
        id
      }
    }
  }
`;
