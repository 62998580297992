import { defineMessages } from "react-intl";

export const ProductTypeMessages = defineMessages({
  Acaricide: {
    defaultMessage: "Acaricida",
    id: "j9kMbj",
    description: "acaricida label",
  },
  Adjuvant: {
    defaultMessage: "Adjuvante",
    id: "28LROD",
    description: "adjuvant label",
  },
  Adulticide: {
    defaultMessage: "Adulticida",
    id: "efF8XW",
    description: "adulticide label",
  },
  Bactericide: {
    defaultMessage: "Bactericida",
    id: "EPNAzA",
    description: "bactericide label",
  },
  Biological: {
    defaultMessage: "Biológico",
    id: "tduiFX",
    description: "biological label",
  },
  Fertilizer: {
    defaultMessage: "Fertilizante",
    id: "ezcBlm",
    description: "fertilizer label",
  },
  FoliarNutrition: {
    defaultMessage: "Nutrição Foliar",
    id: "PKpMhf",
    description: "foliar nutrition label",
  },
  Fungicide: {
    defaultMessage: "Fungicida",
    id: "N53buw",
    description: "fungicide label",
  },
  Herbicide: {
    defaultMessage: "Herbicida",
    id: "n5bWsR",
    description: "herbicide label",
  },
  Insecticide: {
    defaultMessage: "Inseticida",
    id: "dcnDRl",
    description: "insecticide label",
  },
  Larvicide: {
    defaultMessage: "Larvicida",
    id: "us77qG",
    description: "larvicide label",
  },
  Ripener: {
    defaultMessage: "Amadurecido",
    id: "s69KPJ",
    description: "ripener label",
  },
  Nematicide: {
    defaultMessage: "Nematicida",
    id: "3045Ps",
    description: "nematicide label",
  },
  Phytohormone: {
    defaultMessage: "Fitormônio",
    id: "mzPvGn",
    description: "phytohormone label",
  },
  Unknown: {
    defaultMessage: "Desconhecido",
    id: "IeT6wZ",
    description: "unknown label",
  },
  Microbiological: {
    defaultMessage: "Microbiológico",
    id: "TTIKhU",
    description: "microbiological label",
  },
  AntKiller: {
    defaultMessage: "Formicida",
    id: "6oq5wm",
    description: "ant killer label",
  },
  BiologicalControlAgent: {
    defaultMessage: "Controle de Agente Biológico",
    id: "H3EnVE",
    description: "biological control agent label",
  },
  Molluscicide: {
    defaultMessage: "Moluscicida",
    id: "ynVG+m",
    description: "molluscicide label",
  },
  Pheromone: {
    defaultMessage: "Feromônio",
    id: "lVS7Wf",
    description: "pheromone label",
  },
  PlantActivator: {
    defaultMessage: "Ativador de Planta",
    id: "yTj1BC",
    description: "plant activator label",
  },
  PlantGrowthRegulator: {
    defaultMessage: "Regulador de Crescimento",
    id: "F6o0ik",
    description: "plant growth regulator label",
  },
  SeedProtectant: {
    defaultMessage: "Protetor de Sementes",
    id: "/xx0N8",
    description: "seed protectant label",
  },
  Seeding: {
    defaultMessage: "Semeadura",
    id: "Xh/tH5",
    description: "seeding label",
  },
  Termiticide: {
    defaultMessage: "Cupinicida",
    id: "d2dRjS",
    description: "termiticide label",
  },
  AcaricideAntKillerInsecticide: {
    defaultMessage: "Acaricida Formicida Inseticida",
    id: "AWJb8X",
    description: "acaricide ant killer insecticide label",
  },
  AcaricideBactericideFungicide: {
    defaultMessage: "Acaricida Bactericida Fungicida",
    id: "5uyLbh",
    description: "acaricide bactericide fungicide label",
  },
  AcaricideFungicide: {
    defaultMessage: "Acaricida Fungicida",
    id: "PGWOyy",
    description: "acaricide fungicide label",
  },
  AcaricideFungicideInsecticide: {
    defaultMessage: "Acaricida Fungicida Inseticida",
    id: "YX4VTj",
    description: "acaricide fungicide insecticide label",
  },
  AcaricideInsecticide: {
    defaultMessage: "Acaricida Inseticida",
    id: "iD/5h1",
    description: "acaricide insecticide label",
  },
  AcaricideInsecticideNematicide: {
    defaultMessage: "Acaricida Inseticida Nematicida",
    id: "m5a1ID",
    description: "acaricide insecticide nematicide label",
  },
  AcaricideMicrobiological: {
    defaultMessage: "Acaricida Microbiológico",
    id: "NQiPo0",
    description: "acaricide microbiological label",
  },
  AcaricideMicrobiologicalInsecticideMicrobiological: {
    defaultMessage: "Acaricida Microbiológico e Inseticida Microbiológico",
    id: "F26n9X",
    description: "acaricide microbiological insecticide microbiological label",
  },
  AntKillerFungicideHerbicideInsecticideNematicide: {
    defaultMessage: "Formicida Fungicida Herbicida Inseticida Nematicida",
    id: "Y6GHsd",
    description: "ant killer fungicide herbicide insecticide nematicide label",
  },
  AntKillerFungicideHerbicideNematicide: {
    defaultMessage: "Formicida Fungicida Herbicida Nematicida",
    id: "k6ibqq",
    description: "ant killer fungicide herbicide nematicide label",
  },
  AntKillerInsecticide: {
    defaultMessage: "Formicida Inseticida",
    id: "mA65BO",
    description: "ant killer insecticide label",
  },
  BactericideFungicide: {
    defaultMessage: "Bactericida Fungicida",
    id: "qYXG1x",
    description: "bactericide fungicide label",
  },
  BactericideMicrobiologicalFungicideMicrobiological: {
    defaultMessage: "Bactericida Microbiológico e Fungicida Microbiológico",
    id: "qjqRov",
    description: "bactericide microbiological fungicide microbiological label",
  },
  FumigantInsecticide: {
    defaultMessage: "Inseticida Fumigante",
    id: "D/G5cs",
    description: "fumigant insecticide label",
  },
  FungicideHerbicideNematicide: {
    defaultMessage: "Fungicida Herbicida Nematicida",
    id: "FS3/Iu",
    description: "fungicide herbicide nematicide label",
  },
  FungicideInsecticide: {
    defaultMessage: "Fungicida Inseticida",
    id: "ZnB5kh",
    description: "fungicide insecticide label",
  },
  FungicideInsecticideNematicide: {
    defaultMessage: "Fungicida Inseticida Nematicida",
    id: "YrTvO9",
    description: "fungicide insecticide nematicide label",
  },
  FungicideMicrobiological: {
    defaultMessage: "Fungicida Microbiológico",
    id: "Uax5O9",
    description: "fungicide microbiological label",
  },
  FungicideMicrobiologicalNematicideMicrobiological: {
    defaultMessage: "Fungicida Microbiológico e Nematicida Microbiológico",
    id: "lkliJ6",
    description: "fungicide microbiological nematicide microbiological label",
  },
  FungicideNematicide: {
    defaultMessage: "Fungicida e Nematicida",
    id: "HMFxhD",
    description: "fungicide nematicide label",
  },
  FungicideNematicideMicrobiological: {
    defaultMessage: "Fungicida e Nematicida Microbiológico",
    id: "ZqpSbs",
    description: "fungicide nematicide microbiological label",
  },
  HerbicidePlantGrowthRegulator: {
    defaultMessage: "Herbicida e Regulador de Crescimento",
    id: "dsiS2L",
    description: "herbicide plant growth regulator label",
  },
  InsecticideMicrobiological: {
    defaultMessage: "Inseticida Microbiológico",
    id: "6P+vrF",
    description: "insecticide microbiological label",
  },
  InsecticideNematicide: {
    defaultMessage: "Inseticida Nematicida",
    id: "2ahXl6",
    description: "insecticide nematicide label",
  },
  InsecticidePlantGrowthRegulator: {
    defaultMessage: "Inseticida e Regulador de Crescimento",
    id: "i5kmoq",
    description: "insecticide plant growth regulator label",
  },
  NematicideMicrobiological: {
    defaultMessage: "Nematicida Microbiológico",
    id: "NlawRT",
    description: "nematicide microbiological label",
  },
  PheromoneInsecticide: {
    defaultMessage: "Feromônio Inseticida",
    id: "cdThmd",
    description: "pheromone insecticide label",
  },
  TermiticideAntKillerInsecticide: {
    defaultMessage: "Cupinicida Formicida Inseticida",
    id: "RiP2PQ",
    description: "termiticide ant killer insecticide label",
  },
  TermiticideInsecticide: {
    defaultMessage: "Cupinicida Inseticida",
    id: "1kW6ip",
    description: "termiticide insecticide label",
  },
  acaricide: {
    defaultMessage: "Acaricida",
    id: "j9kMbj",
    description: "acaricida label",
  },
  adulticide: {
    defaultMessage: "Adulticida",
    id: "efF8XW",
    description: "adulticide label",
  },
  bactericide: {
    defaultMessage: "Bactericida",
    id: "EPNAzA",
    description: "bactericide label",
  },
  biological: {
    defaultMessage: "Biológico",
    id: "tduiFX",
    description: "biological label",
  },
  fertilizer: {
    defaultMessage: "Fertilizante",
    id: "ezcBlm",
    description: "fertilizer label",
  },
  foliar_nutrition: {
    defaultMessage: "Nutrição Foliar",
    id: "PKpMhf",
    description: "foliar nutrition label",
  },
  fungicide: {
    defaultMessage: "Fungicida",
    id: "N53buw",
    description: "fungicide label",
  },
  herbicide: {
    defaultMessage: "Herbicida",
    id: "n5bWsR",
    description: "herbicide label",
  },
  insecticide: {
    defaultMessage: "Inseticida",
    id: "dcnDRl",
    description: "insecticide label",
  },
  larvicide: {
    defaultMessage: "Larvicida",
    id: "us77qG",
    description: "larvicide label",
  },
  ripener: {
    defaultMessage: "Amadurecido",
    id: "s69KPJ",
    description: "ripener label",
  },
  nematicide: {
    defaultMessage: "Nematicida",
    id: "3045Ps",
    description: "nematicide label",
  },
  phytohormone: {
    defaultMessage: "Fitormônio",
    id: "mzPvGn",
    description: "phytohormone label",
  },
  unknown: {
    defaultMessage: "Desconhecido",
    id: "IeT6wZ",
    description: "unknown label",
  },
  microbiological: {
    defaultMessage: "Microbiológico",
    id: "TTIKhU",
    description: "microbiological label",
  },
  ant_killer: {
    defaultMessage: "Formicida",
    id: "6oq5wm",
    description: "ant killer label",
  },
  biological_control_agent: {
    defaultMessage: "Controle de Agente Biológico",
    id: "H3EnVE",
    description: "biological control agent label",
  },
  molluscicide: {
    defaultMessage: "Moluscicida",
    id: "ynVG+m",
    description: "molluscicide label",
  },
  pheromone: {
    defaultMessage: "Feromônio",
    id: "lVS7Wf",
    description: "pheromone label",
  },
  plant_activator: {
    defaultMessage: "Ativador de Planta",
    id: "yTj1BC",
    description: "plant activator label",
  },
  plant_growth_regulator: {
    defaultMessage: "Regulador de Crescimento",
    id: "F6o0ik",
    description: "plant growth regulator label",
  },
  seed_protectant: {
    defaultMessage: "Protetor de Sementes",
    id: "/xx0N8",
    description: "seed protectant label",
  },
  seeding: {
    defaultMessage: "Semeadura",
    id: "Xh/tH5",
    description: "seeding label",
  },
  termiticide: {
    defaultMessage: "Cupinicida",
    id: "d2dRjS",
    description: "termiticide label",
  },
  acaricide_ant_killer_insecticide: {
    defaultMessage: "Acaricida Formicida Inseticida",
    id: "AWJb8X",
    description: "acaricide ant killer insecticide label",
  },
  acaricide_bactericide_fungicide: {
    defaultMessage: "Acaricida Bactericida Fungicida",
    id: "5uyLbh",
    description: "acaricide bactericide fungicide label",
  },
  acaricide_fungicide: {
    defaultMessage: "Acaricida Fungicida",
    id: "PGWOyy",
    description: "acaricide fungicide label",
  },
  acaricide_fungicide_insecticide: {
    defaultMessage: "Acaricida Fungicida Inseticida",
    id: "YX4VTj",
    description: "acaricide fungicide insecticide label",
  },
  acaricide_insecticide: {
    defaultMessage: "Acaricida Inseticida",
    id: "iD/5h1",
    description: "acaricide insecticide label",
  },
  acaricide_insecticide_nematicide: {
    defaultMessage: "Acaricida Inseticida Nematicida",
    id: "m5a1ID",
    description: "acaricide insecticide nematicide label",
  },
  acaricide_microbiological: {
    defaultMessage: "Acaricida Microbiológico",
    id: "NQiPo0",
    description: "acaricide microbiological label",
  },
  acaricide_microbiological_insecticide_microbiological: {
    defaultMessage: "Acaricida Microbiológico e Inseticida Microbiológico",
    id: "F26n9X",
    description: "acaricide microbiological insecticide microbiological label",
  },
  ant_killer_fungicide_herbicide_insecticide_nematicide: {
    defaultMessage: "Formicida Fungicida Herbicida Inseticida Nematicida",
    id: "Y6GHsd",
    description: "ant killer fungicide herbicide insecticide nematicide label",
  },
  ant_killer_fungicide_herbicide_nematicide: {
    defaultMessage: "Formicida Fungicida Herbicida Nematicida",
    id: "k6ibqq",
    description: "ant killer fungicide herbicide nematicide label",
  },
  ant_killer_insecticide: {
    defaultMessage: "Formicida Inseticida",
    id: "mA65BO",
    description: "ant killer insecticide label",
  },
  bactericide_fungicide: {
    defaultMessage: "Bactericida Fungicida",
    id: "qYXG1x",
    description: "bactericide fungicide label",
  },
  bactericide_microbiological_fungicide_microbiological: {
    defaultMessage: "Bactericida Microbiológico e Fungicida Microbiológico",
    id: "qjqRov",
    description: "bactericide microbiological fungicide microbiological label",
  },
  fumigant_insecticide: {
    defaultMessage: "Inseticida Fumigante",
    id: "D/G5cs",
    description: "fumigant insecticide label",
  },
  fungicide_herbicide_nematicide: {
    defaultMessage: "Fungicida Herbicida Nematicida",
    id: "FS3/Iu",
    description: "fungicide herbicide nematicide label",
  },
  fungicide_insecticide: {
    defaultMessage: "Fungicida Inseticida",
    id: "ZnB5kh",
    description: "fungicide insecticide label",
  },
  fungicide_insecticide_nematicide: {
    defaultMessage: "Fungicida Inseticida Nematicida",
    id: "YrTvO9",
    description: "fungicide insecticide nematicide label",
  },
  fungicide_microbiological: {
    defaultMessage: "Fungicida Microbiológico",
    id: "Uax5O9",
    description: "fungicide microbiological label",
  },
  fungicide_microbiological_nematicide_microbiological: {
    defaultMessage: "Fungicida Microbiológico e Nematicida Microbiológico",
    id: "lkliJ6",
    description: "fungicide microbiological nematicide microbiological label",
  },
  fungicide_nematicide: {
    defaultMessage: "Fungicida e Nematicida",
    id: "HMFxhD",
    description: "fungicide nematicide label",
  },
  fungicide_nematicide_microbiological: {
    defaultMessage: "Fungicida e Nematicida Microbiológico",
    id: "ZqpSbs",
    description: "fungicide nematicide microbiological label",
  },
  herbicide_plant_growth_regulator: {
    defaultMessage: "Herbicida e Regulador de Crescimento",
    id: "dsiS2L",
    description: "herbicide plant growth regulator label",
  },
  insecticide_microbiological: {
    defaultMessage: "Inseticida Microbiológico",
    id: "6P+vrF",
    description: "insecticide microbiological label",
  },
  insecticide_nematicide: {
    defaultMessage: "Inseticida Nematicida",
    id: "2ahXl6",
    description: "insecticide nematicide label",
  },
  insecticide_plant_growth_regulator: {
    defaultMessage: "Inseticida e Regulador de Crescimento",
    id: "i5kmoq",
    description: "insecticide plant growth regulator label",
  },
  nematicide_microbiological: {
    defaultMessage: "Nematicida Microbiológico",
    id: "NlawRT",
    description: "nematicide microbiological label",
  },
  pheromone_insecticide: {
    defaultMessage: "Feromônio Inseticida",
    id: "cdThmd",
    description: "pheromone insecticide label",
  },
  termiticide_ant_killer_insecticide: {
    defaultMessage: "Cupinicida Formicida Inseticida",
    id: "RiP2PQ",
    description: "termiticide ant killer insecticide label",
  },
  termiticide_insecticide: {
    defaultMessage: "Cupinicida Inseticida",
    id: "1kW6ip",
    description: "termiticide insecticide label",
  },
});
